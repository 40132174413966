<template>
  <div class="container pb-2 mt-3 description" v-if="media">
    <video-meta-data :title="media.title" :description="media.description" />
    <div class="row mt-2">
      <subtitle-select
        v-if="media.subtitles.length > 0"
        v-model="activeSubtitle"
        :subtitles="media.subtitles"
      />
      <subtitle-toolbar
        :activeSubtitle="activeSubtitle"
        :fragments="fragments"
        :media="media"
        :settings="settings"
        :acknowledgeSaving="acknowledgeSaving"
        :isUnpersisted="isUnpersisted"
        @toggleTranslationMode="toggleTranslationMode"
        v-if="activeSubtitle"
      />
      <open-subtitle-form-button :useIconVariant="media.subtitles.length > 0" />
    </div>
    <hr />
    <div class="row mt-2" v-if="showTranslate">
      <translation-select v-model="translation" :subtitles="media.subtitles" />
    </div>
    <add-subtitle-form :media="media" />
  </div>
</template>

<script>
import Vue from "vue";
import VideoMetaData from "@/components/show-video/header/VideoMetaData.vue";
import AddSubtitleForm from "@/components/show-video/header/add-subtitle-form/AddSubtitleForm";
import SubtitleToolbar from "@/components/show-video/header/subtitle-toolbar/SubtitleToolbar";
import SubtitleSelect from "@/components/show-video/header/SubtitleSelect.vue";
import TranslationSelect from "@/components/show-video/header/TranslationSelect.vue";
import OpenSubtitleFormButton from "@/components/show-video/header/add-subtitle-form/OpenAddSubtitleFormButton.vue";

export default Vue.extend({
  data: function () {
    return {
      showTranslate: false,
    };
  },
  components: {
    AddSubtitleForm,
    SubtitleToolbar,
    VideoMetaData,
    SubtitleSelect,
    TranslationSelect,
    OpenSubtitleFormButton,
  },
  props: {
    activeSubtitle: Object,
    translation: Object,
    media: Object,
    settings: Object,
    fragments: Array,
    isUnpersisted: Boolean,
    acknowledgeSaving: Function
  },
  methods: {
    toggleTranslationMode: function () {
      this.showTranslate = !this.showTranslate;
      // remove current translation if we toggle off
      if (!this.showTranslate) {
        this.$emit("updateTranslation", { target: { value: null } });
      }
    },
  },
});
</script>
