<template>
  <b-dropdown-form class="dropdown-item-text long-horizontal">
    <div class="input-group mb-1">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Find text"
        ref="findText"
      />
    </div>
    <div class="input-group mb-1">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Replace with"
        ref="replaceText"
      />
    <div class="input-group-append">
    <button
      class="btn btn-outline-secondary btn-sm float-right"
      type="button"
      @click="$_replaceText"
      title="Replace"
    >
      <font-awesome-icon icon="exchange-alt" />
    </button>
    </div>
    </div>
  </b-dropdown-form>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    fragments: Array,
  },
  methods: {
    $_replaceText: function () {
      for (var i = 0; i < this.fragments.length; i++) {
        for (var j = 0; j < this.fragments[i].lines.length; j++) {
          var newText = this.fragments[i].lines[j].replace(
            this.$refs.findText.value,
            this.$refs.replaceText.value
          );
          Vue.set(this.fragments[i].lines, j, newText);
        }
      }
      this.$parent.$parent.$parent.$parent.$emit("callUpdateSubtitle");
    },
  },
});
</script>
<style>
.long-horizontal {
  min-width: 300px;
}
</style>
