<template>
  <div class="col-12 col-md-6">
    <select class="custom-select" @change="updateTranslation">
      <option selected :value="null">Select a subtitle to translate</option>
      <option
        v-bind:key="subtitle.id"
        v-for="subtitle in subtitles"
        :value="subtitle.id"
      >
        {{ subtitle.updated_at }}
        - {{ subtitle.language }}
      </option>
    </select>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["subtitles"],
  methods: {
    updateTranslation: function (event) {
      this.$parent.$emit("updateTranslation", event);
    },
  },
});
</script>
