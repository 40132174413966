
























































import Vue from "vue";
import { Video } from "../types/Video";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";

export default Vue.extend({
  components: { DeleteConfirmationModal },
  data: function (): { videos: Video[]; toBeDeletedId: string } {
    return {
      videos: [],
      toBeDeletedId: "",
    };
  },
  mounted(): void {
    this.fetchData();
  },
  methods: {
    fetchData: function (): void {
      Vue.axios
        .get("/api/video")
        .then((response) => {
          this.videos = response.data;
          response.data.forEach((element: Video, ix: number) => {
            this.getThumbnail(element.id, ix);
          });
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    getThumbnail: function (id: string, ix: number) {
      return Vue.axios
        .get("/storage/thumbnails/" + id, { responseType: "blob" })
        .then((response) => {
          const asBlob = response.data;
          this.videos[ix].thumbnail = URL.createObjectURL(asBlob);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    loaded: function (name: string) {
      URL.revokeObjectURL(name);
    },
    requestDelete: function (id: string) {
      this.toBeDeletedId = id;
      this.$bvModal.show('delete_modal')
    },
    deleteVideo: function (id: string): void {
      Vue.axios
        .delete(`/api/video/${id}`)
        .then(() => {
          this.fetchData();
          Vue.toasted.show("Video deleted", {
            theme: "toasted-primary",
            type: "success",
            position: "bottom-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          Vue.toasted.show(
            `Error when deleting video: ${error.response.data.message}`,
            {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-right",
              duration: 3000,
            }
          );
        });
    },
  },
  filters: {
    truncate: function (text: string, length: number, clamp: string): string {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent || "";
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
});
