<template>
  <div class="dropdown d-inline">
    <b-dropdown
      type="button"
      id="downloadDropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title="Download subtitle"
    >
      <template #button-content>
        <font-awesome-icon icon="cloud-download-alt" />
      </template>
      <b-dropdown-item
        target="_blank"
        class="dropdown-item"
        v-if="status === 'SYNCHRONIZED'"
        @click="$_download('srt')"
        >SRT</b-dropdown-item
      >
      <b-dropdown-item
        target="_blank"
        @click="$_download('segmented')"
        class="dropdown-item"
        v-if="status === 'SYNCHRONIZED' || status === 'SEGMENTED'"
        >Segmented</b-dropdown-item
      >
      <b-dropdown-item
        target="_blank"
        @click="$_download('plain')"
        class="dropdown-item"
        v-if="status !== 'CREATED'"
        >Plain text</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from 'file-saver';

library.add(faCloudDownloadAlt);

export default Vue.extend({
  props: ["mediaId", "subtitleId", "status"],
  methods: {
    $_download: function (format) {
      Vue.axios
        .get(
          `/api/video/${this.mediaId}/subtitle/${this.subtitleId}/download/${format}`
        )
        .then((response) => {
          let blob = new Blob([response.data], { type: "octet/stream" });
          const fileName = `${this.mediaId}_${this.subtitleId}${format === 'srt' ? '.srt' : `_${format}.txt`}`;

          saveAs(blob, fileName);
          this.showToast("Download completed");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
});
</script>
