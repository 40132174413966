<template>
  <b-dropdown-form class="dropdown-item-text">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        v-bind:checked="showOnlyWrong"
        @change="$emit('toggleShowOnlyWrong')"
        id="defaultCheck1"
      />
      <label class="form-check-label" for="defaultCheck1">
        Show only wrong subs
      </label>
    </div>
  </b-dropdown-form>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["showOnlyWrong"],
});
</script>
