import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "../components/Index.vue";
import VideoListPage from "@/components/VideoListPage.vue";
import AddVideoPage from "@/components/AddVideoPage.vue";
import ShowVideoPage from "@/components/show-video/BaseShowVideoPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Index",
    component: Index,
    
  },
  {
    path: "/videos",
    name: "Videos",
    component: VideoListPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/videos/add",
    name: "addVideo",
    component: AddVideoPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/videos/:id",
    name: "openVideo",
    component: ShowVideoPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];


const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Vue.prototype.$keycloak.authenticated) {
      next()
    } else {
      const loginUrl = Vue.prototype.$keycloak.createLoginUrl()
      window.location.replace(loginUrl)
    }
  } else {
    next()
  }
})

export default router;
