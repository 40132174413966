<template>
  <div class="sticky">
    <video-player
      ref="video"
      :showVideoPlayer="showVideoPlayer"
      :media="media"
      :fragments="fragments"
      :pauseWhileTyping="pauseWhileTyping"
    />
    <media-waveform
      v-show="showTimeline"
      v-if="fragments"
      :playerStatus="getPlayerStatus()"
      :subtitleProp="fragments"
      :currentTime="getCurrentTime()"
      @goToSecond="goToSecond"
      @enableUpdate="fragments.update = true"
      @updateTime="updateTime"
      @updateSubtitle="$parent.$emit('callUpdateSubtitle')"
    />
  </div>
</template>
<script>
import Vue from "vue";

import MediaWaveform from "@/components/show-video/video-preview/MediaWaveForm";
import VideoPlayer from "@/components/show-video/video-preview/VideoPlayer.vue";

export default Vue.extend({
  components: {
    MediaWaveform,
    VideoPlayer,
  },
  props: {
    showVideoPlayer: Boolean,
    showTimeline: Boolean,
    media: Object,
    fragments: Array,
    pauseWhileTyping: Boolean,
  },
  methods: {
    getPlayerStatus: function () {
      return this.$refs.video.player.paused();
    },
    getCurrentTime: function () {
      return this.$refs.video.getCurrentTime();
    },
    goToSecond: function (time) {
      this.$refs.video.goToSecond(time);
    },
    getActiveCue: function () {
      return this.$refs.video.getActiveCue();
    },
    pauseVideo: function () {
      this.$refs.video.pauseVideo();
    },
    forcePause: function () {
      this.$refs.video.forcePause();
    },
    updateTime: function (region) {
      this.fragments[region.id].begin = parseFloat(region.start).toFixed(3);
      this.fragments[region.id].end = parseFloat(region.end).toFixed(3);
      this.fragments.update = false;
      this.fragments.splice(region.id, 1, this.fragments[region.id]);
    },
  },
});
</script>
