<template>
  <button
    type="button"
    class="btn btn-primary"
    @click="$_startSegmentation"
    title="Segment plain text"
  >
    Segment plain text
  </button>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { toastedConfig } from "@/utils/constants";
library.add(faMagic);

export default Vue.extend({
  props: {
    requestUrl: String,
  },
  methods: {
    $_startSegmentation: function () {
      Vue.axios
        .patch(`${this.requestUrl}/segment`)
        .then(() => {
          this.$toasted.show("Segmentation finished", toastedConfig);
        })
        .catch((error) => {
          console.log(error.response);
          this.$toasted.show("Segmentation had errors", toastedConfig);
        });
    },
  },
});
</script>
