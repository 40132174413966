<template>
  <button
    type="button"
    class="btn btn-secondary"
    @click="$parent.$emit('toggleTranslationMode')"
    title="Enable translation mode"
  >
    <font-awesome-icon icon="language" />
  </button>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
library.add(faLanguage);

export default Vue.extend({});
</script>
