<template>
  <div
    ref="video"
    class="container-fluid bg-black"
    v-if="media"
    v-show="showVideoPlayer"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-7 mx-auto">
          <video
            id="my-player"
            class="video-js vjs-big-play-centered vjs-16-9"
            controls
            preload="auto"
          >
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="http://videojs.com/html5-video-support/"
                target="_blank"
                rel="noopener"
              >
                supports HTML5 video
              </a>
            </p>
          </video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import videojs from "video.js";
import "videojs-youtube";

import { backendBaseUrl } from "@/main";

import "video.js/dist/video-js.css";

export default Vue.extend({
  components: {},
  props: {
    showVideoPlayer: Boolean,
    media: Object,
    fragments: Array,
    pauseWhileTyping: Boolean,
  },
  mounted() {
    this.$nextTick(() => {
      this.player = videojs("my-player", this.playerOptions);
      if (this.youtubeLink) {
        this.player.src({ type: "video/youtube", src: this.media.link });
      } else {
        //TODO: use a better way to define this link
        this.player.src({
          type: "video/mp4",
          src: backendBaseUrl + "/storage/video/" + this.$route.params.id,
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.player.on("durationchange", function () {
        that.maxTime = this.duration();
      });

      this.player.on("timeupdate", function () {
        that.currentTime = this.currentTime();
      });
    });
  },
  data: function () {
    return {
      playerOptions: {
        language: "en",
        playbackRates: [0.5, 0.75, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        techOrder: ["html5", "youtube"],
      },
      currentTime: 0,
      maxTime: 0,
      activeCue: null,
      player: null,
    };
  },
  computed: {
    youtubeLink: function () {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      var match = this.media.link.match(regExp);
      if (match && match[2].length == 11) {
        return `https://www.youtube.com/embed/${match[2]}`;
      }
      return false;
    },
  },
  methods: {
    getActiveCue: function () {
      if (this.fragments) {
        var textTracks = this.getRemoteTextTracks();
        if (textTracks.tracks_.length > 0) {
          this.player.removeRemoteTextTrack(textTracks.tracks_[0]);
        }
        var textTrack = this.getTextTrack();

        textTracks = this.getRemoteTextTracks();
        var activeTrack = textTracks.tracks_[0];
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;

        activeTrack.mode = "showing";

        activeTrack.oncuechange = function () {
          that.activeCue = this.activeCues[0];
          that.$parent.$emit("callUpdateActiveCue", that.activeCue);
        };

        textTrack.onload = function () {
          if (!that.activeCue) {
            that.activeCue = this.track.cues[0];
            that.$parent.$parent.$emit("callUpdateActiveCue", that.activeCue);
          }
        };
        this.activeCue = that.activeCue;
      }
      return this.activeCue;
    },
    goToSecond: function (time) {
      if (time != this.player.currentTime()) {
        this.player.currentTime(time);
      }
    },
    pauseVideo: function () {
      if (this.pauseWhileTyping) {
        this.player.pause();
      }
    },
    forcePause: function () {
      this.player.pause();
    },
    getTextTrack: function () {
      return this.player.addRemoteTextTrack(
        {
          src: this.getVTTBlobURL(),
          srclang: "en",
          mode: "showing",
        },
        false
      );
    },
    getCurrentTime: function () {
      return this.currentTime;
    },
    getRemoteTextTracks: function () {
      return this.player.remoteTextTracks();
    },
    getVTTBlobURL: function () {
      var blob = new Blob([this.getSubtitlesVTT()], { type: "text/vtt" });
      return URL.createObjectURL(blob);
    },
    getSubtitlesVTT: function () {
      let sub = "WEBVTT FILE\n\n";

      var fragments = this.fragments;
      var formatTime = function (time) {
        var totalSeconds = time.split(".")[0];
        var ms = time.split(".")[1];
        var h = Math.floor(totalSeconds / 3600)
          .toString()
          .padStart(2, "0");
        var m = Math.floor((totalSeconds % 3600) / 60)
          .toString()
          .padStart(2, "0");
        var s = Math.floor((totalSeconds % 3600) % 60)
          .toString()
          .padStart(2, "0");
        return h + ":" + m + ":" + s + "." + ms;
      };
      for (var i = 0; i < fragments.length; i++) {
        if (!fragments[i].begin && !fragments[i].end) {
          continue;
        }
        sub += i + 1 + "\n";
        sub +=
          formatTime(fragments[i].begin) +
          " --> " +
          formatTime(fragments[i].end) +
          "\n";

        for (var j = 0; j < fragments[i].lines.length; j++) {
          sub += fragments[i].lines[j] + "\n";
        }
        sub += "\n";
      }

      return sub;
    },
  },
  watch: {
    showVideoPlayer: function () {
      this.player.pause();
    },
    fragments: function () {
      this.getActiveCue();
    },
  },
  beforeDestroy() {
    this.player.dispose();
  },
});
</script>
