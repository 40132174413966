<template>
  <div v-bind:class="useIconVariant && 'col-4 text-center'">
    <div class="col-4 d-flex flex-grow-1 align-items-center">
      <b-button
        type="button"
        class="btn btn-primary"
        v-b-modal.addSubtitleModal
      >
        <font-awesome-icon icon="plus-square" v-if="useIconVariant" />
        <span v-else>Add a new subtitle</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    useIconVariant: Boolean,
  },
});
</script>
