<template>
  <div class="dropdown d-inline">
    <b-dropdown
      type="button"
      id="settingsDropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title="Subtitle Settings"
    >
      <template #button-content>
        <font-awesome-icon icon="cog" />
      </template>
      <show-video-player-checkbox
        :showVideoPlayer="settings.showVideoPlayer"
        @toggleShowVideoPlayer="
          settings.showVideoPlayer = !settings.showVideoPlayer
        "
      />
      <show-timeline-checkbox
        :showTimeline="settings.showTimeline"
        @toggleShowTimeline="settings.showTimeline = !settings.showTimeline"
      />
      <pause-while-typing-checkbox
        :pauseWhileTyping="settings.pauseWhileTyping"
        @togglePauseWhileTyping="
          settings.pauseWhileTyping = !settings.pauseWhileTyping
        "
      />
      <show-only-wrong-subs-checkbox
        :showOnlyWrong="settings.showOnlyWrong"
        @toggleShowOnlyWrong="settings.showOnlyWrong = !settings.showOnlyWrong"
      />
      <b-dropdown-item-button
        class="dropdown-item"
        @click="removeEmptyFragments"
      >
        Remove empty lines
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import ShowVideoPlayerCheckbox from "@/components/show-video/header/subtitle-toolbar/video-settings/checkboxes/ShowVideoPlayerCheckbox.vue";
import ShowTimelineCheckbox from "@/components/show-video/header/subtitle-toolbar/video-settings/checkboxes/ShowTimelineCheckbox.vue";
import PauseWhileTypingCheckbox from "@/components/show-video/header/subtitle-toolbar/video-settings/checkboxes/PauseWhileTypingCheckbox.vue";
import ShowOnlyWrongSubsCheckbox from "@/components/show-video/header/subtitle-toolbar/video-settings/checkboxes/ShowOnlyWrongSubsCheckbox.vue";
import {
  faCog
} from "@fortawesome/free-solid-svg-icons";
library.add(faCog);

export default Vue.extend({
  props: [
    "mediaId",
    "subtitleId",
    "settings",
    "fragments",
    "removeEmptyFragments",
  ],
  components: {
    ShowVideoPlayerCheckbox,
    ShowTimelineCheckbox,
    PauseWhileTypingCheckbox,
    ShowOnlyWrongSubsCheckbox
  },
});
</script>
