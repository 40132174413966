import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Toasted from "vue-toasted";
import refreshPlugin from "./utils/refreshNotification";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Import Keycloak stuff
import VueKeyCloak from '@caassis/vue-keycloak-ts'

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// eslint-disable-next-line
Vue.use(require("vue-shortkey"));
Vue.use(VueAxios, axios);
Vue.use(Toasted);
Vue.use(refreshPlugin, {})


export const backendBaseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export const backendWebSocketUrl = process.env.VUE_APP_BACKEND_NOTIFICATION_URL;

axios.defaults.baseURL = backendBaseUrl;
const keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL;
const keyCloakRealm = process.env.VUE_APP_KEYCLOAK_REALM;
const keycloakClientId = process.env.VUE_APP_KEYCLOAK_CLIENT_ID
const logoutRedirectUri = process.env.VUE_APP_LOGOUT_REDIRECT_URI;

const keycloakOptions = {
  authRealm: keyCloakRealm,
  authUrl: keycloakUrl,
  authClientId: keycloakClientId,
  logoutRedirectUri,
}

  Vue.axios
  .get("/api/config/current")
  .then((response) => {
    Vue.prototype.$config = response.data
  })
  .catch((error) => {
    console.log(error.response.data.message);
  });

Vue.use(VueKeyCloak, {
  config: keycloakOptions,
  init: {
     onLoad: 'check-sso',
     silentCheckSsoRedirectUri: window.location.origin+'/silent-check-sso.html',
  },

  onReady: (kc: any) => {
    // const updateToken = (): string => {
    //   return kc.token as string;
    // }
    axios.interceptors.request.use(async config => {
      // const token = await updateToken();
      const token = kc.token
      if (kc.token)
      config.headers.common['Authorization'] = `Bearer ${token}`;
      return config;
    });
    
    
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})


