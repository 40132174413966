import Vue from "vue"


export const refreshNeeded = Vue.observable({ refreshStatus: {refreshPending: false,
  mediaId: null,
  subtitleId: null // or subtitle?
 }})
    
export default {
    install: () => {
        Object.defineProperty(
          Vue.prototype, '$refreshData', {  
            get () {
              return refreshNeeded.refreshStatus
            },    
            set (value) {    
              refreshNeeded.refreshStatus = value
            }
          }
        )
    }
  }


   