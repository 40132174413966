<template>
  <b-modal @ok="deleteFun" id="delete_modal" title="Delete?" hide-header-close>
    <p class="my-4">Are you sure you want to delete?</p>
  </b-modal>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    deleteFun: Function,
  }
});
</script>