<template>
  <div
    class="row border-bottom pb-1 pt-1"
    :class="{ current: activeCueTime == fragment.begin, error: !isCorrect }"
    @contextmenu.prevent="$refs.menu.open"
  >
    <div
      class="col-3 col-md-1 highlightSubtitle align-self-center"
      @click="goToSecond(fragment.begin)"
      v-if="editable && !isTranslating"
    >
      <span>{{ getFormattedBegin }}</span
      ><br />
      <span>{{ getFormattedEnd }}</span>
    </div>
    <div
      class="text-container"
      @click="isEditing = true"
      :class="[editable ? 'col-8 col-md-7 col-lg-7' : 'col-12']"
    >
      <div style="position: relative; height: 100%">
        <div
          class="backdrop w-100 h-100 px-2"
          ref="backdrop"
          :class="{ active: isEditing }"
          v-if="editable"
        >
          <div class="highlights" v-html="getSubtitleTextHighlighted"></div>
        </div>
        <textarea
          class="px-2 subtitle"
          ref="textarea"
          v-shortkey.once="{ above: ['f2'], below: ['f3'], split: ['f4'] }"
          @shortkey="shortCuts"
          rows="2"
          v-model="subtitleText"
          @blur="updateLines"
          @scroll="scrollBackdrop"
          @focus="emitPause"
          v-if="editable"
        />
        <p v-else>{{ subtitleText }}</p>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-4 align-self-center" v-if="editable && !isTranslating">
      <dl class="row justify-content-center mb-0">
        <dt class="col-sm-3 col-lg-1" v-if="getFirstLineLength > maxChars">
          <i
            class="mdi mdi-text-short"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Line length shouldn't exceed 42 characters; add a line break if necessary."
          />
        </dt>
        <dd class="col-sm-9 col-lg-11" v-if="getFirstLineLength > maxChars">
          Line 1 length: {{ getFirstLineLength }} char.
        </dd>
        <dt class="col-sm-3 col-lg-1" v-if="getSecondLineLength > maxChars">
          <i
            class="mdi mdi-text-short"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Line length shouldn't exceed 42 characters; add a line break if necessary."
          />
        </dt>
        <dd class="col-sm-9 col-lg-11" v-if="getSecondLineLength > maxChars">
          Line 2 length: {{ getSecondLineLength }} char.
        </dd>
        <dt class="col-sm-3 col-lg-1" v-if="getNumberLines > maxLines">
          <i
            class="mdi mdi-text-subject"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Avoid more than 2 lines per subtitle; split the subtitle into two."
          />
        </dt>
        <dd class="col-sm-9 col-lg-11" v-if="getNumberLines > maxLines">
          Number of lines: {{ getNumberLines }}
        </dd>
        <dt class="col-sm-3 col-lg-1" v-if="getLineRatio < ratio">
          <i
            class="mdi mdi-format-color-fill"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="When using unbalanced lines to preserve linguistic units, make sure that one line is never less than 50% in length of the other."
          />
        </dt>
        <dd class="col-sm-9 col-lg-11" v-if="getLineRatio < ratio">
          Line Ratio: {{ getLineRatio }}
        </dd>
        <dt class="col-sm-3 col-lg-1" v-if="getCharPerSecond > charsPerSec">
          <i
            class="mdi mdi-clock-outline"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Reading rate shouldn't exceed 21 characters / sec; lengthen duration, reduce text or split the subtitle."
          />
        </dt>
        <dd class="col-sm-9 col-lg-11" v-if="getCharPerSecond > charsPerSec">
          Chars / sec {{ getCharPerSecond }}
        </dd>
        <dt
          class="col-sm-3 col-lg-1"
          v-if="getDuration < minDuration || getDuration > maxDuration"
        >
          <i
            class="mdi mdi-clock-outline"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Subtitle duration shouldn't be shorter than 1 seconds or longer than 7 seconds."
          />
        </dt>
        <dd
          class="col-sm-9 col-lg-11"
          v-if="getDuration < minDuration || getDuration > maxDuration"
        >
          Duration {{ getDuration }} seconds
        </dd>
      </dl>
      <context-menu ref="menu" class="vue-context-container">
        <li @click="emitEventMenu(1)">Add after</li>
        <li @click="emitEventMenu(2)">Add before</li>
        <li @click="emitEventMenu(3)">Duplicate</li>
        <li @click="emitEventMenu(4)">Split lines</li>
        <li @click="emitEventMenu(5)">Merge above</li>
        <li @click="emitEventMenu(6)">Merge below</li>
        <li @click="emitEventMenu(7)">Delete</li>
      </context-menu>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import contextMenu from "vue-context-menu";

export default Vue.extend({
  components: {
    contextMenu,
  },
  props: {
    fragmentProp: {
      type: Object,
      required: true,
    },
    activeCueTime: {
      type: Number,
      required: true,
    },
    subtitle: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchText: {
      type: String,
      required: false,
      default: null,
    },
    isTranslating: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: function () {
    return {
      isEditing: false,
      fragment: this.fragmentProp,
      maxDuration: this.$config.max_duration,
      minDuration: this.$config.min_duration,
      ratio: this.$config.ratio,
      charsPerSec: this.$config.chars_per_sec,
      maxLines: this.$config.max_lines,
      maxChars: this.$config.max_chars,
    };
  },
  mounted() {
    this.fragment.isCorrect = this.isCorrect; //.bind(this.fragment);
    this.$emit("updateItem", this.fragment, this.$vnode.key);
  },
  computed: {
    getTotalLength: function () {
      return this.getFirstLineLength + this.getSecondLineLength;
    },
    getFirstLineLength: function () {
      if (this.fragment.lines.length > 0) {
        return this.fragment.lines[0].length;
      }
      return 0;
    },
    getSecondLineLength: function () {
      if (this.fragment.lines.length > 1) {
        return this.fragment.lines[1].length;
      }
      return 0;
    },
    getNumberLines: function () {
      return this.fragment.lines.length;
    },
    getCharPerSecond: function () {
      return parseFloat(
        (this.getTotalLength / (this.getEnd - this.getBegin)).toFixed(2)
      );
    },
    getDuration: function () {
      return parseFloat((this.getEnd - this.getBegin).toFixed(3));
    },
    getLineRatio: function () {
      if (this.getSecondLineLength == 0) {
        return 1;
      }
      if (this.getFirstLineLength > this.getSecondLineLength) {
        return parseFloat(
          (this.getSecondLineLength / this.getFirstLineLength).toFixed(2)
        );
      }
      return parseFloat(
        (this.getFirstLineLength / this.getSecondLineLength).toFixed(2)
      );
    },
    getBegin() {
      return parseFloat(this.fragment.begin);
    },
    getEnd() {
      return parseFloat(this.fragment.end);
    },
    getFormattedBegin() {
      var d = new Date(parseFloat(this.fragment.begin) * 1000);
      var hours = ("0" + d.getUTCHours()).slice(-2);
      var minutes = ("0" + d.getUTCMinutes()).slice(-2);
      var seconds = ("0" + d.getUTCSeconds()).slice(-2);
      var millis = ("00" + d.getUTCMilliseconds()).slice(-3);
      return hours + ":" + minutes + ":" + seconds + "." + millis;
    },
    getFormattedEnd() {
      var d = new Date(parseFloat(this.fragment.end) * 1000);
      var hours = ("0" + d.getUTCHours()).slice(-2);
      var minutes = ("0" + d.getUTCMinutes()).slice(-2);
      var seconds = ("0" + d.getUTCSeconds()).slice(-2);
      var millis = ("00" + d.getUTCMilliseconds()).slice(-3);
      return hours + ":" + minutes + ":" + seconds + "." + millis;
    },
    isCorrect: function () {
      return this.getCharPerSecond <= this.charsPerSec &&
        this.getFirstLineLength <= this.maxChars &&
        this.getSecondLineLength <= this.maxChars &&
        this.getLineRatio >= this.ratio &&
        this.getNumberLines <= this.maxLines &&
        this.getDuration <= this.maxDuration &&
        this.getDuration >= this.minDuration;
    },
    getSubtitleTextHighlighted() {
      var lines = [...this.fragment.lines];
      for (var i = 0; i < lines.length; i++) {
        var first = lines[i].substring(0, this.maxChars);
        first = first.replace(
          this.searchText,
          `<mark class='find'>${this.searchText}</mark>`
        );
        var second = lines[i].substring(this.maxChars);
        second = second.replace(
          this.searchText,
          `<mark class='find'>${this.searchText}</mark>`
        );

        lines[i] = first + "<mark class='error'>" + second + "</mark>";
      }
      lines.push("<br>");
      return lines.join("<br>");
    },
    subtitleText: {
      get: function () {
        return this.fragment.lines.join("\n");
      },
      set: function (newValue) {
        this.fragment.lines = newValue.split("\n");
        this.fragment.isCorrect = this.isCorrect;
        this.$emit("updateItem", this.fragment, this.$vnode.key);
      },
    },
  },
  methods: {
    emitEventMenu(event, text) {
      this.$emit("subtitleAction", event, this.$vnode.key, text);
    },
    goToSecond: function (time) {
      time = Math.round((parseFloat(time) + 0.001) * 1000, 3) / 1000;
      this.$emit("goToSecond", time, this);
    },
    enableEditing: function () {
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    updateLines: function () {
      this.isEditing = false;
      this.$emit("updateSubtitle");
    },
    scrollBackdrop: function (event) {
      if (this.editable) {
        this.$refs.backdrop.scrollTop = this.$refs.textarea.scrollTop;
      }
    },
    emitPause: function (event) {
      this.$emit("pauseVideo");
    },
    shortCuts: function (event) {
      if (event.srcKey == "above") {
        var tmp = this.subtitleText.substring(0, event.target.selectionStart);
        this.subtitleText = this.subtitleText.substring(
          event.target.selectionStart
        );
        this.emitEventMenu(8, tmp);
      } else if (event.srcKey == "below") {
        // eslint-disable-next-line no-redeclare
        var tmp = this.subtitleText.substring(event.target.selectionStart);
        this.subtitleText = this.subtitleText.substring(
          0,
          event.target.selectionStart
        );
        this.emitEventMenu(9, tmp);
      } else if (event.srcKey == "split") {
        this.emitEventMenu(4);
      }
    },
  },
  watch: {
    activeCueTime: function (val) {
      if (this.editable && this.activeCueTime == this.fragment.begin) {
        var sticky = document.querySelector(".sticky");
        var navbar = document.querySelector("#nav");
        var description = document.querySelector(".description");

        // TODO: Discuss where to scroll to
        // window.scrollTo({
        //   top: sticky.clientHeight + description.clientHeight,
        //   behavior: "smooth",
        // });
      }
    },
    fragmentProp: function () {
      this.fragment = this.fragmentProp;
    },
  },
});
</script>

<style>
.highlightSubtitle:hover span {
  background-color: #fffbe5;
  cursor: pointer;
}

.highlightSubtitle:hover {
  cursor: pointer;
}

textarea.subtitle {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  border-radius: 0;
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
  border: none;
  font: 16px "Open Sans", sans-serif;
  letter-spacing: 1px;
}

.backdrop {
  position: absolute;
  z-index: 1;
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
}

.highlights {
  font: 16px "Open Sans", sans-serif;
  letter-spacing: 1px;
  color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
}

mark.error {
  border-radius: 3px;
  color: transparent;
  padding: 0px;
  background-color: #dc3545;
}

mark.find {
  border-radius: 3px;
  color: transparent;
  padding: 0px;
  background-color: #ffed4a;
}

.backdrop.active {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mdi {
  display: inline-flex;
  vertical-align: middle;
}

.text-container {
  min-height: 50px;
}

div.error {
  background-color: rgba(220, 53, 69, 0.3) !important;
}

div.current {
  background-color: rgba(108, 178, 235, 0.5) !important;
}

.vue-context-container li {
  padding: 2px;
  padding-left: 10px;
}

.vue-context-container li:hover {
  background-color: #eee;
}
</style>

<style scoped>
div * {
  font-size: 14px;
}
</style>
