<template>
  <div class="dropdown d-inline">
    <b-dropdown
      type="button"
      id="searchDropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <template #button-content>
        <font-awesome-icon icon="search" />
      </template>
      <search-value-input />
      <find-replace-input :fragments="fragments" />
    </b-dropdown>
  </div>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import SearchValueInput from "@/components/show-video/header/subtitle-toolbar/search-component/SearchValueInput.vue";
import FindReplaceInput from "@/components/show-video/header/subtitle-toolbar/search-component/FindReplaceInput.vue";
import {
  faSearchPlus,
  faSearch,
  faExchangeAlt
} from "@fortawesome/free-solid-svg-icons";
library.add(faSearchPlus);
library.add(faSearch);
library.add(faExchangeAlt);

export default Vue.extend({
  props: [
    "fragments",
  ],
  components: {
    SearchValueInput,
    FindReplaceInput,
  },
});
</script>

