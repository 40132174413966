<template>
  <span>
    <button
      type="button"
      class="btn btn-danger"
      @click="requestDelete"
      title="Delete subtitle"
    >
      <font-awesome-icon icon="trash-alt" />
    </button>
    <delete-confirmation-modal
      :deleteFun="this.deleteSubtitle"
    />
  </span>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toastedConfig } from "@/utils/constants";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
library.add(faTrashAlt);

export default Vue.extend({
  components: { DeleteConfirmationModal },
  props: ["mediaId", "subtitleId"],
  methods: {
    requestDelete: function () {
      this.$bvModal.show("delete_modal");
    },
    deleteSubtitle: function () {
      Vue.axios
        .delete(`/api/video/${this.mediaId}/subtitle/${this.subtitleId}`)
        .then(() => {
          this.$parent.$parent.$emit("deleteSubtitle", this.subtitleId);
          this.$toasted.show("Subtitle deleted", toastedConfig);
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
  },
});
</script>
