<template>
  <div>
    <div class="jumbotron jumbotron-fluid py-4 shadow">
      <div class="container">
        <h1 class="display-4">SubberBuddy</h1>
        <h2>A Web Application to Support the TED Translators Initiative</h2>
        <hr />
        <p class="lead">
          <a href="https://www.ted.com" target="_blank">TED</a> is a global
          community devoted to spreading ideas, usually in the form of short,
          powerful talks known as
          <a href="https://www.ted.com/talks" target="_blank">TED Talks</a>. TED
          began in 1984 as a conference where Technology, Entertainment and
          Design converged. Today, TED Talks cover all topics, from science to
          business to global issues. In parallel,
          <a
            href="https://www.ted.com/participate/organize-a-local-tedx-event/before-you-start/what-is-a-tedx-event"
            target="_blank"
            >TEDx</a
          >
          events support the TED mission by bringing the TED experience in local
          communities around the world.
        </p>
        <p class="lead">
          TED and TEDx Talks are recorded in more than 100 languages. The
          language barrier is an obstacle to the TED mission: spreading ideas.
          In 2009, TED created a system to allow volunteers to translate talks
          into any language. Nowadays, the
          <a href="https://www.ted.com/participate/translate" target="_blank"
            >TED Translators</a
          >
          Community counts more than 30 thousands volunteers who performed more
          than 140 thousands translations in more than 100 languages.
        </p>
        <p class="lead">
          Subtitling a talk is a non trivial and very time consuming task. Until
          now, it has been carried out almost entirely without the support of
          tools. In recent years, the organizers of
          <a href="https://tedxlakecomo.com" target="_blank">TEDxLakeComo</a>,
          <a href="https://www.tedxvarese.com" target="_blank">TEDxVarese</a>,
          and
          <a href="http://tedxcesena.com" target="_blank">TEDxCesena</a> started
          to adopt a series of tools to reduce the time needed to generate high
          quality subtitles. SubberBuddy is the first step towards a unified and
          effective toolchain to support, and partially automate, the creation
          of subtitles for TED and TEDx Talks. SubberBuddy features
          <a href="https://www.speechmatics.com" target="_blank"
            >automatic text-to-speech</a
          >,
          <a href="https://pypi.org/project/lachesis/" target="_blank"
            >automatic segmentation</a
          >
          of a transcript into closed captions,
          <a href=" https://www.readbeyond.it/aeneas/" target="_blank"
            >automatic alignment</a
          >, and ensures that all subtitles respect the
          <a
            href="https://www.ted.com/participate/translate/guidelines"
            target="_blank"
            >TED Guidelines</a
          >.
        </p>

        <p class="lead">
          This project has been developed at the
          <a href="https://si.usi.ch" target="_blank"
            >Software Institute (USI)</a
          >
          by
          <a href="https://codelounge.si.usi.ch" target="_blank">CodeLounge</a>.
          If you have questions or feedback, please
          <a
            href="mailto:roberto.minelli@usi.ch?cc=gianella.michele@gmail.com&subject=[SubberBuddy] Request Additional Information"
            >contact us</a
          >.
        </p>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-10 col-sm-3 mx-auto mt-5 align-self-center">
          <a href="https://www.si.usi.ch/" target="_blank">
            <img
              src="/images/Logo_SI_Big@1200px.png"
              class="img-fluid"
              alt="Logo"
            />
          </a>
        </div>
        <div class="col-10 col-sm-3 mx-auto mt-5 align-self-center">
          <a href="https://codelounge.si.usi.ch" target="_blank">
            <img
              src="/images/CodeLounge-Logo.png"
              class="img-fluid"
              alt="Logo"
            />
          </a>
        </div>
        <div class="col-10 col-sm-3 mx-auto mt-5 align-self-center">
          <a href="https://www.tedxlakecomo.com/" target="_blank">
            <img
              src="/images/TEDxLakeComo-Logo.png"
              class="img-fluid"
              alt="Logo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
