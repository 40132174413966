<template>
  <div class="container-fluid bg-grey">
    <div class="container">
      <div class="row">
        <div class="col-12 p-0">
          <div id="waveform" :class="[loaded ? 'visible' : 'invisible']">
            <div
              id="wave-timeline"
              :class="[loaded ? 'visible' : 'invisible']"
            ></div>
          </div>

          <!-- 12 => 5 margin and 7 width -->
          <div
            class="waveform-loader"
            v-if="!loaded"
            v-bind:style="{ width: `${spanCount * 12}px` }"
          >
            <span
              v-for="n in spanCount"
              v-bind:key="n"
              v-bind:style="{
                animation: `load 2.5s ${Math.abs(
                  0.2 * Math.floor(spanCount / 2) - 0.2 * (n - 1)
                )}s infinite linear`,
              }"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import Regions from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import Timeline from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import Minimap from "wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js";
import Cursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js";
import Vue from "vue";
import { backendBaseUrl } from "../../../main";

export default Vue.extend({
  props: {
    subtitleProp: {
      type: Array,
      required: true,
    },
    playerStatus: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: 0.0,
    },
  },
  data: function () {
    return {
      subtitle: this.subtitleProp,
      wavesurfer: null,
      loaded: false,
      setValue: true,
      spanCount: 21,
    };
  },
  mounted() {
    // Fix for safari
    var AudioContext =
      window.AudioContext || window.webkitAudioContext || false;
    var audioCtx = new AudioContext();
    this.wavesurfer = WaveSurfer.create({
      container: "#waveform",
      height: 100,
      minPxPerSec: 100,
      pixelRatio: 1,
      scrollParent: true,
      minimap: true,
      audioContext: audioCtx,
      volume: 0,
      plugins: [
        Regions.create(),
        Timeline.create({
          container: "#wave-timeline",
        }),
        Minimap.create({
          height: 30,
          waveColor: "#ddd",
          progressColor: "#999",
          cursorColor: "#999",
        }),
        Cursor.create({
          showTime: true,
        }),
      ],
    });

    this.wavesurfer.on("region-mouseenter", (region) => {
      region.element.style.border = "1px black solid";
    });
    this.wavesurfer.on("region-mouseleave", (region) => {
      region.element.style.border = "0";
    });
    this.wavesurfer.on("seek", (value) => {
      if (value > 0 && this.setValue) {
        this.$emit("goToSecond", value * this.wavesurfer.getDuration());
      }
      this.setValue = true;
    });

    this.wavesurfer.on("region-updated", (region) => {
      this.$emit("updateTime", region);
    });
    this.wavesurfer.on("region-update-end", () => {
      this.$emit("enableUpdate");
      this.$emit("updateSubtitle");
    });

    this.wavesurfer.setMute(true);
    this.wavesurfer.on("ready", () => {
      this.loaded = true;

      this.loadRegions();
      var secondsRange = 25;
      this.zoomLevel = Math.trunc(
        this.wavesurfer.drawer.getWidth() /
          secondsRange /
          this.wavesurfer.params.pixelRatio
      );
      this.wavesurfer.zoom(this.zoomLevel);
    });

    Vue.axios
        .get(`/storage/audio/${this.$route.params.id}.mp3`, {responseType: "blob"})
        .then((response) => {
          this.wavesurfer.loadBlob(response.data)
        })
  },
  methods: {
    loadRegions: function () {
      this.wavesurfer.regions.clear();

      for (var i = 0; i < this.subtitleProp.length; i++) {
        var region = {
          id: i,
          start: this.subtitleProp[i].begin,
          end: this.subtitleProp[i].end,
          isCorrect: this.subtitleProp[i].isCorrect,
          attributes: { label: this.subtitleProp[i].lines.join(" ") },
          drag: true,
          resize: true,
        };
        this.wavesurfer.addRegion(region);
      }
    },
    updateRegions: function () {
      var regions = this.wavesurfer.regions.list;

      for (var i = 0; i < this.subtitleProp.length; i++) {
        if (regions[i]) {
          regions[i].update({
            attributes: { label: this.subtitleProp[i].lines.join(" ") },
            isCorrect: this.subtitleProp[i].isCorrect,
          });
        } else {
          var region = {
            id: i,
            start: this.subtitleProp[i].begin,
            end: this.subtitleProp[i].end,
            isCorrect: this.subtitleProp[i].isCorrect,
            attributes: { label: this.subtitleProp[i].lines.join(" ") },
            drag: true,
            resize: true,
          };
          this.wavesurfer.addRegion(region);
        }
      }
    },
  },
  watch: {
    currentTime: function (val) {
      this.setValue = false;
      var time = val / this.wavesurfer.getDuration();
      time = time > 1 ? 1 : time;
      time = time < 0 ? 0 : time;
      this.wavesurfer.seekAndCenter(time);
      if (this.playerStatus) {
        this.wavesurfer.pause();
      }
    },
    subtitleProp: function (val) {
      if (val.update) {
        this.updateRegions();
      }
    },
    playerStatus: function () {
      if (this.playerStatus) {
        this.wavesurfer.pause();
      } else {
        this.wavesurfer.play();
      }
    },
  },
});
</script>

<style>
#waveform {
  position: relative;
}

region.wavesurfer-region {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

region.wavesurfer-region:before {
  content: attr(data-region-label);
  top: 0;
}

handle.wavesurfer-handle {
  width: 8px !important;
  max-width: none !important;
}

handle.wavesurfer-handle.wavesurfer-handle-end {
  right: 0px !important;
  left: auto !important;
}
/*https://codepen.io/MyXoToD/pen/Djnbq*/
.waveform-loader {
  margin-left: auto;
  margin-right: auto;
  margin-top: -145px;
  margin-bottom: 45px;
  height: 150px;
  display: flex;
  align-items: center;
}
.waveform-loader span {
  display: block;
  background: #ccc;
  width: 7px;
  height: 10%;
  border-radius: 14px;
  margin-right: 5px;
  float: left;
  margin-top: 30%;
}
.waveform-loader span:last-child {
  margin-right: 0px;
}

@keyframes load {
  0% {
    background: #ccc;
    height: 10%;
  }
  50% {
    background: #444;
    height: 90%;
  }
  100% {
    background: #ccc;
    height: 10%;
  }
}
</style>
