<template>
  <div class="mt-3">
    <show-video-header 
      v-if="media" 
      :media="media" 
      :translation="translation" 
      :activeSubtitle="activeSubtitle"
      :fragments="fragments" 
      :settings="settings" 
      :searchValue="searchValue" 
      :acknowledgeSaving="() => detectChange(false)"
      :isUnpersisted="activeSubtitleHasBeenModified"
      @callUpdateSearchValue="updateSearchValue"
      @callUpdateSubtitle="updateSubtitle" 
      @updateTranslation="onTranslationSelected($event.target.value)"
      @updateActiveSubtitle="onSubtitleSelected($event.target.value)" 
      @deleteSubtitle="onDeleteSubtitle($subtitleId)" />

    <base-video-preview ref="video" 
      v-if="media" 
      :media="media" 
      :showTimeline="settings.showTimeline"
      :showVideoPlayer="settings.showVideoPlayer" 
      :pauseWhileTyping="settings.pauseWhileTyping" 
      :fragments="fragments"
      @callUpdateSubtitle="updateSubtitle" 
      @callUpdateActiveCue="updateActiveCue" />

    <base-subtitle-edit 
      ref="subtitle_edit" 
      :translation="translation" 
      :media="media" 
      :activeSubtitle="activeSubtitle"
      :activeCue="activeCue" 
      :fragments="fragments" 
      :showOnlyWrong="settings.showOnlyWrong" 
      :pauseVideo="pauseVideo"
      :goToSecond="goToSecond" 
      :searchValue="searchValue" 
      :fragmentsTranslation="fragmentsTranslation"
      @detectChange="detectChange"
      @callUpdateSubtitle="updateSubtitle" />
  </div>
</template>
<script>
import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import BaseVideoPreview from "@/components/show-video/video-preview/BaseVideoPreview.vue";
import BaseSubtitleEdit from "@/components/show-video/subtitle-edit/BaseSubtitleEdit.vue";
import bsCustomFileInput from "bs-custom-file-input";

Vue.component("font-awesome-icon", FontAwesomeIcon);
import "@/assets/show_video_page.css";
import ShowVideoHeader from "@/components/show-video/header/ShowVideoHeader.vue";

export default Vue.extend({
  components: {
    ShowVideoHeader,
    BaseVideoPreview,
    BaseSubtitleEdit,
  },
  data: function () {
    return {
      media: null,
      activeSubtitle: null,
      translation: null,
      activeCue: null,
      activeSubtitleHasBeenModified: false,
      fragments: null,
      fragmentsTranslation: null,
      searchValue: null,
      settings: {
        showVideoPlayer: true,
        showTimeline: true,
        showOnlyWrong: false,
        pauseWhileTyping: false,
      },
    };
  },
  mounted() {
    bsCustomFileInput.init();
    // TODO: Reimplement this
    // $(document).on("click", ".dropdown", function (e) {
    //   e.stopPropagation();
    // });
    Vue.axios
      .get(`/api/video/${this.$route.params.id}`)
      .then((response) => {
        this.media = response.data;
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  },
  methods: {
    getUpdatedVideo: function (id, callback) {
      Vue.axios
      .get(`/api/video/${id}`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
    },

    onSubtitleSelected: function (selectedSubtitleId) {
      this.activeSubtitle = this.media.subtitles.find(
        (subtitle) => subtitle.id == selectedSubtitleId
      );
    },
    detectChange: function (updateHasBeenDone) {
      this.activeSubtitleHasBeenModified = updateHasBeenDone;
    },
    onTranslationSelected: function (selectedSubtitleId) {
      this.translation = this.media.subtitles.find(
        (subtitle) => subtitle.id == selectedSubtitleId
      );
    },
    onDeleteSubtitle: function (subtitleId) {
      this.activeSubtitle = null;
      const indexOfToBeRemoved = this.media.subtitles
        .map((subtitle) => subtitle.id)
        .indexOf(subtitleId);
      this.media.subtitles.splice(indexOfToBeRemoved, 1);
    },
    updateSearchValue: function (value) {
      this.searchValue = value;
    },
    updateSubtitle: function () {
      this.$refs.subtitle_edit.saveSubtitle();
      this.updateActiveCue(this.$refs.video.getActiveCue())
    },
    updateActiveCue: function (cue) {
      this.activeCue = cue;
    },
    pauseVideo: function () {
      this.$refs.video.pauseVideo();
    },
    goToSecond: function (time) {
      this.$refs.video.goToSecond(time);
    },
  },
  watch: {
    $refreshData (newVal, oldVal) {
      if (!newVal.refreshPending || newVal.mediaId != this.media.id) {
        return;
      }
      this.getUpdatedVideo(this.media.id, (response) => { 
        const updatedSubtitle = response.subtitles.find( elem =>elem.id == newVal.subtitleId);
        for (var i = 0; i < this.media.subtitles.length; i++) {
          if (this.media.subtitles[i].id == newVal.subtitleId) {
            this.media.subtitles[i] = updatedSubtitle;
            break;
            }
          }
        });
      this.$refreshData = { refreshPending: false, mediaId: null, subtitleId: null };
    }
    ,
    activeSubtitleHasBeenModified: function (newVal, oldVal) {
      if (newVal) {
        this.$toasted.show("There are pending changes", {
          theme: "toasted-primary",
          position: "bottom-right",
        });
      } else {
        this.$toasted.clear();
      }

    },
    activeSubtitle: function (newVal, oldVal) {
      if (!newVal) {
        this.fragments = null;
        return;
      }
      if (this.activeSubtitle.subtitle) {
        this.fragments = JSON.parse(this.activeSubtitle.subtitle).fragments;
        this.fragments.update = true;
        //we want to persist changes only if the id is the same (aka: changing subtitle should not persist the new one)
        if (oldVal && oldVal.subtitle.id === this.activeSubtitle.id) {
          this.updateSubtitle();
        }
        else {
          this.updateActiveCue(this.$refs.video.getActiveCue());
        }
      } else {
        this.fragments = null;
      }
    },
    translation: function (val) {
      if (!val) {
        this.fragmentsTranslation = null;
        return;
      }
      if (this.translation.subtitle) {
        this.fragmentsTranslation = JSON.parse(
          this.translation.subtitle
        ).fragments;
      } else {
        this.fragmentsTranslation = null;
      }
    },
  },
});
</script>
