<template>
  <button type="button" class="btn btn-primary" @click="sendSubtitleToBackend" title="Save subtitle">
    <font-awesome-icon icon="save" />
  </button>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSave } from "@fortawesome/free-solid-svg-icons";
library.add(faSave);

export default Vue.extend({
  props: {
    requestUrl: String,
    updatedSubtitle: Object,
    acknowledgeSaving: Function,
  },
  methods: {
    sendSubtitleToBackend: function () {
      Vue.axios
        .put(
          this.requestUrl,
          this.updatedSubtitle
        )
        .then((response) => { this.acknowledgeSaving()})
        .catch((error) => {
          console.log(error.response);
        });
    }
  },
});
</script>
