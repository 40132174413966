<template>
  <div :class="[translation ? 'container-fluid' : 'container']">
    
    <div class="row" v-if="media">
      <div
        id="plainTextContainer"
        class="col-12"
        v-if="
          activeSubtitle &&
          activeSubtitle.plain_text &&
          !activeSubtitle.segmented &&
          !fragments
        "
      >
        <div class="form-group">
          <textarea
            class="form-control"
            rows="20"
            v-model="activeSubtitle.plain_text"
            @blur="saveSubtitle"
          ></textarea>
        </div>
      </div>
      <div
        id="segmentationContainer"
        class="col-12"
        v-if="activeSubtitle && activeSubtitle.segmented && !fragments"
      >
        <div class="form-group">
          <textarea
            class="form-control"
            id="segmentationTextarea"
            rows="20"
            v-model="activeSubtitle.segmented"
            @blur="saveSubtitle"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row" v-if="fragments">
      <!-- Translation subtitles -->
      <div class="col" v-if="translation" >
        <div class="row position-relative">
          <div id="subtitlesContainer" class="col-12 grid-striped border-top">
            <subtitle-item
              v-for="(fragment, index) in fragmentsTranslation"
              :key="index + 1"
              :fragmentProp="fragment"
              :subtitle="activeSubtitle"
              :activeCueTime="getActiveCueStartTime"
              :editable="false"
              v-show="showOnlyWrong ? !fragment.isCorrect : true"
            />
          </div>
        </div>
      </div>
      <!-- Normal subtitles -->
      <div class="col">
        <div class="row position-relative">
          <div id="subtitlesContainer" class="col-12 border-top">
            <subtitle-item
              v-for="(fragment, index) in sortedFragments"
              :key="index + 1"
              :fragmentProp="fragment"
              :subtitle="activeSubtitle"
              :activeCueTime="getActiveCueStartTime"
              :searchText="searchValue"
              :isTranslating="translation"
              @goToSecond="goToSecond"
              @updateSubtitle="$_callUpdateSubtitle"
              @updateItem="updateItem"
              @subtitleAction="subtitleAction"
              @pauseVideo="pauseVideo"
              v-show="showOnlyWrong ? !fragment.isCorrect : true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SubtitleItem from "./SubtitleItem.vue";

export default Vue.extend({
  components: {
    SubtitleItem,
  },
  props: {
    translation: Object,
    media: Object,
    activeSubtitle: Object,
    activeCue: Object,
    fragments: Array,
    showOnlyWrong: Boolean,
    pauseVideo: Function,
    goToSecond: Function,
    searchValue: String,
    fragmentsTranslation: Array,
  },
  methods: {
    $_callUpdateSubtitle: function () {
      this.$emit("callUpdateSubtitle");
    },
    saveSubtitle: function () {
      if (this.fragments) {
        var tmp = { fragments: this.fragments };
        this.activeSubtitle.subtitle = JSON.stringify(tmp);
      }
      this.$emit("detectChange", true);
    },
    subtitleAction: function (event, id, text) {
      id = id - 1;
      var current = this.fragments[id];
      var tmp = {
        begin: current.begin,
        end: current.end,
        language: current.language,
        children: [],
        id: "",
        lines: [],
      };
      if (event == 1) {
        tmp.begin = tmp.end;
        tmp.end = (parseFloat(tmp.end) + 1).toString();
        this.fragments.splice(id + 1, 0, tmp);
      } else if (event == 2) {
        tmp.end = tmp.begin;
        tmp.begin = (parseFloat(tmp.begin) - 1).toString();
        this.fragments.splice(id, 0, tmp);
      } else if (event == 3) {
        tmp.lines = current.lines;
        this.fragments.splice(id + 1, 0, tmp);
      } else if (event == 4) {
        var total_length = current.lines.reduce(
          (total, el) => total + el.length,
          0
        );
        var begin = parseFloat(current.begin);
        var end = parseFloat(current.begin);
        for (var i = 0; i < current.lines.length; i++) {
          var ratio = current.lines[i].length / total_length;
          var sub_length = current.end - current.begin;
          var length = Math.round(ratio * sub_length * 1000) / 1000;
          end += length;
          // eslint-disable-next-line no-redeclare
          var tmp = {
            begin: begin.toString(10),
            end: end.toString(10),
            language: current.language,
            children: [],
            id: "",
            lines: [current.lines[i]],
          };
          begin += length;
          this.fragments.splice(id + 1 + i, 0, tmp);
        }
        this.fragments.splice(id, 1);
      } else if (event == 5) {
        id = id - 1;
        current = this.fragments[id];
        var next = this.fragments[id + 1];
        if (!next) {
          return;
        }
        current.end = next.end;
        current.lines = current.lines.concat(next.lines);
        this.fragments.splice(id + 1, 1);
      } else if (event == 6) {
        // eslint-disable-next-line no-redeclare
        var next = this.fragments[id + 1];
        if (!next) {
          return;
        }
        current.end = next.end;
        current.lines = current.lines.concat(next.lines);
        this.fragments.splice(id + 1, 1);
      } else if (event == 7) {
        this.fragments.splice(id, 1);
      } else if (event == 8) {
        id = id - 1;
        var prev = this.fragments[id];
        if (!prev) {
          return;
        }
        prev.lines.push(text);
      } else if (event == 9) {
        id = id + 1;
        // eslint-disable-next-line no-redeclare
        var next = this.fragments[id];
        if (!next) {
          return;
        }
        next.lines.unshift(text);
      }
      this.$_callUpdateSubtitle
    },
    updateItem: function (item, id) {
      id = id - 1;
      this.fragments.splice(id, 1, item);
      this.fragments.update = true;
    },
  },
  computed: {
    getActiveCueStartTime() {
      return this.activeCue ? this.activeCue.startTime : -1;
    },
    sortedFragments() {
      return [...this.fragments].sort((a, b) => a.begin - b.begin);
    },
  },
});
</script>
