<template>
  <button
    type="button"
    class="btn btn-secondary"
    @click="$_autoFix"
    title="Auto fix subtitle"
  >
    <font-awesome-icon icon="magic" />
  </button>
</template>

<script>
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
library.add(faMagic);

export default Vue.extend({
  props: {
    mediaId: Number,
    subtitleId: Number,
    removeEmptyFragments: Function,
  },
  methods: {
    $_autoFix: function () {
      this.removeEmptyFragments();
      Vue.axios
        .patch(`/api/video/${this.mediaId}/subtitle/${this.subtitleId}/autofix`)
        .then(() => {
          this.showToast("Autofix started");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
});
</script>
