<template>
  <b-dropdown-form class="dropdown-item-text">
    <div class="input-group mb-1">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="Search text"
        ref="searchText"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary btn-sm float-right"
          type="button"
          @click="$_searchText"
          title="Search"
        >
          <font-awesome-icon icon="search-plus" />
        </button>
      </div>
    </div>
  </b-dropdown-form>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  methods: {
    $_searchText: function () {
      this.$parent.$parent.$parent.$parent.$emit(
        "callUpdateSearchValue",
        this.$refs.searchText.value
      );
    },
  },
});
</script>
