<template>
  <div class="col-4 mt-2 mt-md-0 separate-first">
    <save-subtitle-button
      v-if="isUnpersisted"
      :updatedSubtitle="this.activeSubtitle"
      :requestUrl="requestUrl"
      :acknowledgeSaving="acknowledgeSaving"
    />
    
    <segment-button
      v-if="activeSubtitle.status == 'TRANSCRIBED' && !isUnpersisted"
      :requestUrl="requestUrl"
    />
    <synchronize-button
      v-if="activeSubtitle.status == 'SEGMENTED' && !isUnpersisted"
      :requestUrl="requestUrl"
    />
    <toggle-translate-button v-if="fragments && !isUnpersisted" />
    <video-settings
      v-if="fragments"
      :settings="settings"
      :fragments="fragments"
      :removeEmptyFragments="removeEmptyFragments"
    />
    <search-component
      v-if="fragments"
      :fragments="fragments"
    />
    <download-subtitle-button
      v-if="activeSubtitle.status !== 'CREATED'"
      :mediaId="media.id"
      :subtitleId="activeSubtitle.id"
      :status="activeSubtitle.status"
    />
    <autofix-subtitle-button
      v-if="fragments"
      :mediaId="media.id"
      :subtitleId="activeSubtitle.id"
      :removeEmptyFragments="removeEmptyFragments"
    />
    <delete-subtitle-button
      :mediaId="media.id"
      :subtitleId="activeSubtitle.id"
    />
  </div>
</template>

<script>
import Vue from "vue";
import AutofixSubtitleButton from "./buttons/AutofixSubtitleButton.vue";
import ToggleTranslateButton from "./buttons/ToggleTranslateButton.vue";
import DeleteSubtitleButton from "./buttons/DeleteSubtitleButton.vue";
import SaveSubtitleButton from "./buttons/SaveSubtitleButton.vue";
import SegmentButton from "./buttons/SegmentButton.vue";
import SynchronizeButton from "./buttons/SynchronizeButton.vue";
import DownloadSubtitleButton from "./buttons/DownloadSubtitleButton.vue";
import VideoSettings from "./video-settings/BaseVideoSettings.vue";
import SearchComponent from "./search-component/SearchComponent.vue";

export default Vue.extend({
  components: {
    AutofixSubtitleButton,
    DeleteSubtitleButton,
    ToggleTranslateButton,
    SaveSubtitleButton,
    SegmentButton,
    SynchronizeButton,
    VideoSettings,
    SearchComponent,
    DownloadSubtitleButton,
  },
  props: {
    useIconVariant: Boolean,
    activeSubtitle: Object,
    media: Object,
    settings: Object,
    fragments: Array,
    acknowledgeSaving: Function,
    isUnpersisted: Boolean,
  },
  methods: {
    $_callUpdateSubtitle: function () {
      this.$parent.$emit("callUpdateSubtitle");
    },
    // this only removes completely empty ones, not fragments including an empty line
    // TODO: Ask Roberto if this is intended
    removeEmptyFragments: function () {
      for (var i = 0; i < this.fragments.length; i++) {
        if (this.fragments[i].lines.length == 0) {
          this.fragments.splice(i, 1);
        } else {
          if (this.fragments[i].lines.every((line) => line == "")) {
            this.fragments.splice(i, 1);
          }
        }
      }
      this.$_callUpdateSubtitle();
    },
  },
  computed: {
    requestUrl: function () {
      return `/api/video/${this.media.id}/subtitle/${this.activeSubtitle.id}`;
    },
  },
});
</script>
<style>
  .separate-first > button:first-child {
    margin-right: 5px;
  }
  
</style>