<template>
  <div class="row">
    <h3 class="text-truncate">{{ title }}</h3>
    <p>{{ description }}</p>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    title: String,
    description: String,
  },
});
</script>
