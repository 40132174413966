<template>
  <div>
    <b-modal
      class="modal fade text-left"
      id="addSubtitleModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      ok-title="Add"
      @ok="submitForm"
      title="Add new subtitle"
      hide-header-close
    >
      <div class="modal-body">
        <form ref="segmentationForm" enctype="multipart/form-data">
          <div class="form-group">
            <label>Subtitle language</label>
            <select class="form-control" name="language">
              <option value="en" :selected="media.language=='en' " >English</option>
              <option value="it" :selected="media.language=='it' ">Italian</option>
            </select>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="requestType"
                id="transcribeSubtitles"
                value="transcribeSubtitles"
                v-model="requestType"
              />
              <label class="form-check-label" for="transcribeSubtitles">
                Transcribe text from audio
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="requestType"
                id="uploadPlainText"
                value="uploadPlainText"
                v-model="requestType"
              />
              <label class="form-check-label" for="uploadPlainText">
                Plain text
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="requestType"
                id="uploadSegmentedText"
                value="uploadSegmentedText"
                v-model="requestType"
              />
              <label class="form-check-label" for="uploadSegmentedText">
                Segmented
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="requestType"
                id="uploadSubtitle"
                value="uploadSubtitle"
                v-model="requestType"
              />
              <label class="form-check-label" for="uploadSubtitle">
                Subtitle
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="requestType"
                id="createChildSubtitle"
                value="createChildSubtitle"
                v-model="requestType"
                :disabled="subtitles.length == 0"
              />
              <label class="form-check-label" for="createChildSubtitle">
                Create child subtitle (used for translation)
              </label>
            </div>
          </div>
          <div
            class="form-group"
            v-if="
              requestType !== 'transcribeSubtitles' &&
              requestType !== 'createChildSubtitle'
            "
          >
            <label>File</label>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                ref="textFile"
                :accept="acceptedTypes"
                required
              />
              <label class="custom-file-label">Choose file</label>
              <small
                class="form-text text-muted"
                v-if="requestType === 'uploadPlainText'"
              >
                The plain text will be segmented
              </small>
              <small
                class="form-text text-muted"
                v-if="requestType === 'uploadSegmentedText'"
              >
                Each fragment must be separeted by an empty line
              </small>
              <small
                class="form-text text-muted"
                v-if="requestType === 'uploadSubtitle'"
              >
                Supported files: SRT, VTT, STL, SBV, SUB, ASS
              </small>
            </div>
          </div>
          <div class="form-group" v-if="requestType === 'createChildSubtitle'">
            <label>Subtitle a subtitle to copy</label>
            <select class="form-control" name="subtitleId">
              <option
                v-bind:key="subtitle.id"
                :value="subtitle.id"
                v-for="subtitle in subtitles"
              >
                {{ subtitle.updated_at }} - {{ subtitle.language }}
              </option>
            </select>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import bsCustomFileInput from "bs-custom-file-input";
import Vue from "vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import NodeFormData from "form-data";

library.add(faPlusSquare);

export default Vue.extend({
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      subtitles: [],
      requestType: 3,
    };
  },
  mounted() {
    bsCustomFileInput.init();
    this.subtitles = this.media.subtitles;
  },
  computed: {
    acceptedTypes() {
      if (
        this.requestType === "uploadPlainText" ||
        this.requestType === "uploadSegmentedText"
      ) {
        return ".txt";
      }
      return ".SRT, .VTT, .STL, .SBV, .SUB, .ASS";
    },
  },
  methods: {
    submitForm: function () {
      let inputForm = new FormData(this.$refs.segmentationForm);
      inputForm.append("media_id", this.media.id);
      const json = JSON.stringify(Object.fromEntries(inputForm));

      let formData = new NodeFormData();
      formData.append(
        "data",
        new Blob([json], {
          type: "application/json",
        })
      );

      if (this.hasTextFile()) {
        formData.append("textFile", this.$refs.textFile.files[0]);
      }

      Vue.axios
        .post(`/api/video/${this.media.id}/subtitle`, formData, {})
        .then((response) => {

          this.subtitles.push(response.data);

          switch (this.requestType) {
            case "transcribeSubtitles":
              return this.showToast("Transcription started");
            case "uploadPlainText":
              return this.showToast("Segmentation started");
            case "uploadSegmentedText":
              return this.showToast("Segmented text uploaded");
            case "uploadSubtitle":
              return this.showToast("Subtitle uploaded");
            case "createChildSubtitle":
              return this.showToast("Creating child subtitle");
          }
        })
        .catch((error) => {
          console.error(error.response.data.message);
        });
    },
    showToast: function (message) {
      this.$toasted.show(message, {
        theme: "toasted-primary",
        position: "bottom-right",
        duration: 5000,
      });
    },
    hasTextFile: function () {
      return (
        this.requestType !== "transcribeSubtitles" &&
        this.requestType !== "createChildSubtitle"
      );
    },
  },
});
</script>
