<template>
  <button
    type="button"
    class="btn btn-primary"
    @click="$_startSynchronize"
    title="Synchronize subtitle"
  >
  Sync
  </button>
</template>

<script>
import Vue from "vue";
import { toastedConfig } from "@/utils/constants";

export default Vue.extend({
  props: {
    requestUrl: String,
  },
  methods: {
    $_startSynchronize: function () {
      Vue.axios
        .patch(`${this.requestUrl}/synchronize`)
        .then(() => {
          this.$toasted.show("Synchronization started", toastedConfig);
        })
        .catch((error) => {
          console.log(error.response);
          this.$toasted.show("Synchronization had errors", toastedConfig);
        });
    },
  },
});
</script>
