import { render, staticRenderFns } from "./SegmentButton.vue?vue&type=template&id=475fdc51&"
import script from "./SegmentButton.vue?vue&type=script&lang=js&"
export * from "./SegmentButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports