<template>
  <div class="container mt-3">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8">
        <h3>Add video</h3>
        <hr />
      </div>
      <div class="col-12 col-lg-8">
        <form @submit.prevent="submitForm" ref="mediaForm">
          <div class="form-group">
            <label>Title</label>
            <input
              type="txt"
              class="form-control"
              name="title"
              placeholder="Title"
            />
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea
              class="form-control"
              name="description"
              placeholder="Description"
              rows="3"
            />
          </div>
          <div class="form-group">
            <label>Original language</label>
            <select class="form-control" name="language">
              <option value="en" selected="selected">English</option>
              <option value="it">Italian</option>
            </select>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="fileType"
                id="fileType1"
                value="0"
                v-model="fileType"
              />
              <label class="form-check-label" for="fileType1">
                I have a file
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="fileType"
                id="fileType2"
                value="1"
                v-model="fileType"
              />
              <label class="form-check-label" for="fileType2">
                I have a link to a video
              </label>
            </div>
          </div>
          <div class="form-group" v-if="fileType == 0">
            <label>Video file</label>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                ref="mediaFile"
                accept="video/mp4,video/x-m4v,video/*"
                required
              />
            </div>
          </div>
          <div class="form-group" v-if="fileType == 1">
            <label>Video link</label>
            <input
              type="url"
              class="form-control"
              name="link"
              placeholder="Video link"
              required
            />
          </div>
          <button type="submit" class="btn btn-primary" :disabled="uploading">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import bsCustomFileInput from "bs-custom-file-input";
import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {
      fileType: 0,
      uploading: false,
    };
  },
  mounted() {
    bsCustomFileInput.init();
  },
  methods: {
    submitForm: function () {
      this.uploading = true;
      let formData = new FormData(this.$refs.mediaForm);
      if (this.fileType == 0) {
        formData.append("videoFile", this.$refs.mediaFile.files[0]);
      }
      Vue.axios
        .post("/api/video", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data = response.data;
          this.$router.push({ name: "openVideo", params: { id: data.id } });
        })
        .catch((error) => {
          this.uploading = false;
          const errorMessage = error.message || error.response.data.message;
          this.showToast(errorMessage, "danger");
          console.log(errorMessage);
        });
    },
    showToast: function (message) {
      this.$toasted.show(message, {
        theme: "toasted-primary",
        position: "bottom-right",
        duration: 5000,
      });
    },
  },
});
</script>
