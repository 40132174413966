<template>
  <div class="col-4 d-flex flex-grow-1 align-items-center">
    <select class="custom-select" @change="updateActiveSubtitle">
      <option selected :value="null">Select a subtitle</option>
      <option
        v-bind:key="subtitle.id"
        v-for="subtitle in subtitles"
        :value="subtitle.id"
      >
        {{ subtitle.updated_at }}
        - {{ subtitle.language }}
      </option>
    </select>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["subtitles"],
  methods: {
    updateActiveSubtitle: function (event) {
      this.$parent.$emit("updateActiveSubtitle", event);
    },
  },
});
</script>
