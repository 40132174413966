




















import Vue from 'vue'

import SockJS from "sockjs-client";
import Stomp, {Client} from "webstomp-client";
import { backendBaseUrl, backendWebSocketUrl } from './main';



export default Vue.extend({
data: function() { return {authenticated: false, connected: false,
   socket: null as WebSocket | null, stompClient: null as Client | null,

}},  
mounted(): void {
   this.authenticated = Vue.prototype.$keycloak.authenticated
   if (this.authenticated && !this.connected) {
     this.connect()
   }
},
methods: {
  connect : function() {
    this.socket = new SockJS(backendBaseUrl + "/ws");
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.connect(
      {"Authorization": Vue.prototype.$keycloak.token},
      frame => {
        const topic = this.generateNotificationSubscriptionChannel()
        this.connected = true;
        this.stompClient!.subscribe(topic, tick => {
          const messageBody = JSON.parse(tick.body)
           this.$toasted.show(messageBody.message, 
           { theme: "toasted-primary",
             position: "bottom-right",
           })
           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
           // @ts-ignore
           this.$refreshData = { refreshPending: true, 
                                 mediaId: messageBody.videoId, 
                                 subtitleId: messageBody.subtitleId } 
        });
      },
      error => {
        console.log(error);
        this.connected = false;
      }
    );
  },
  disconnect: function() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
    this.connected = false;
  },
  generateNotificationSubscriptionChannel: function() {
    const sessionIdentifier : string = this.stompClient!.ws._transport.url
                    .replace(backendWebSocketUrl,  "")
                    .replace("/websocket", "")
                    .replace(/^\/[0-9]+\//, "")
    return"/queue/notification-user"+sessionIdentifier
  }
}
})

